import React, { useEffect, useRef, useState } from 'react';
import { withPrefix } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';

import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import CodeIcon from '@mui/icons-material/Code';
import ContactForm from '../ContactForm';
import './team.css';

export function getTeamMemberEmail(teamMember, siteConfig) {
  return teamMember.email
    ? siteConfig.id !== 'mi'
      ? teamMember.email.split('MovingIN.com').join(siteConfig.domain)
      : teamMember.email
    : '';
}

export const teams = {
  sales: {
    teamName: 'The Incredible Team',
    members: {
      // andrea: {
      //   name: 'Andrea Blanchard',
      //   title: 'Managing Partner',
      //   subtitle: 'Sales/Portfolio Relationships',
      //   phone: '(602) 698-7606',
      //   email: 'Andrea@MovingIN.com',
      //   linkedIn: 'https://www.linkedin.com/in/andreablanchard/',
      //   image: withPrefix('/images/team/andrea.png'),
      //   color: '#f6b418'
      // },
      // eve: {
      //   name: 'Eve Rines',
      //   title: 'Managing Partner',
      //   subtitle: 'Sales/Ancillary Strategy',
      //   phone: '(602) 698-7608',
      //   email: 'Eve@MovingIN.com',
      //   linkedIn: 'https://www.linkedin.com/in/eve-rines/',
      //   image: withPrefix('/images/team/eve.png?v=2'),
      //   color: '#99ccbe'
      // },
      bud: {
        name: 'Bud Bowman',
        title: 'President',
        phone: '(602) 622-7133',
        email: 'Bud@MovingIN.com',
        linkedIn: 'https://www.linkedin.com/in/bud-bowman-4b5b76220/',
        image: withPrefix('/images/team/bud2.png'),
        color: '#e46166'
      },
      audrey: {
        name: 'Audrey Clark',
        title: 'Client Success Manager',
        phone: '(602) 698-7621',
        email: 'Audrey@MovingIN.com',
        linkedIn: 'http://www.linkedin.com/in/audreyclark7621',
        image: withPrefix('/images/team/audrey.png'),
        color: '#c58db2'
      },
      beth: {
        name: 'Beth Rines',
        title: 'Client Success Manager',
        phone: '(602) 698-7612',
        email: 'Beth@MovingIN.com',
        //linkedIn: 'http://www.linkedin.com/in/beth-boddeker',
        image: withPrefix('/images/team/beth.png'),
        color: '#fbb3b6'
      },
      kim: {
        name: 'Kimberlee Marton',
        title: 'Managing Partner',
        subtitle: 'Deregulated Markets',
        phone: '(602) 698-7598',
        email: 'Kim@MovingIN.com',
        linkedIn: 'https://www.linkedin.com/in/kimberleem/',
        image: withPrefix('/images/team/kim.png'),
        color: '#c4ecf4'
      }
    }
  },
  dev: {
    teamName: 'In-house Development Team',
    members: {
      kurt: {
        name: 'Kurt Sprinzl',
        title: 'CTO',
        phone: '(602) 791-8802',
        email: 'Kurt@MovingIN.com',
        //linkedIn: 'https://www.linkedin.com/in/andreablanchard/',
        image: withPrefix('/images/team/kurt.png?v=2'),
        color: '#5983a7'
      },
      // clint: {
      //   name: 'Clint Houston',
      //   title: 'CFO',
      //   //phone: '(555) 555-5555',
      //   email: 'Clint@MovingIN.com',
      //   //linkedIn: 'https://www.linkedin.com/in/andreablanchard/',
      //   image: withPrefix('/images/team/clint.png'),
      //   color: '#8dabc5'
      // },
      bill: {
        name: 'Bill Mitchell',
        title: 'Director of IT',
        //phone: '(555) 555-5555',
        email: 'Bill@MovingIN.com',
        //linkedIn: 'https://www.linkedin.com/in/andreablanchard/',
        image: withPrefix('/images/team/bill.png'),
        color: '#313d70'
      },

      sonny: {
        name: 'Sonny Ward',
        title: 'Web Developer',
        //phone: '(555) 555-5555',
        email: 'Sonny@MovingIN.com',
        //linkedIn: 'https://www.linkedin.com/in/andreablanchard/',
        image: withPrefix('/images/team/sonny.png'),
        color: '#8dc5a7'
      }
    }
  }
};

function TeamContact({ teamMember, siteConfig }) {
  function getEmail() {
    return getTeamMemberEmail(teamMember, siteConfig);
  }

  return (
    <>
      {teamMember.title ? (
        <div className="contact">{teamMember.title}</div>
      ) : null}
      {teamMember.subtitle ? (
        <div className="contact">{teamMember.subtitle}</div>
      ) : null}
      {teamMember.phone ? (
        <div className="contact">
          <a href={'tel:' + teamMember.phone}>{teamMember.phone}</a>
        </div>
      ) : null}
      {teamMember.email ? (
        <div className="contact">
          <a href={'mailto:' + getEmail()}>{getEmail()}</a>
        </div>
      ) : null}

      {teamMember.linkedIn && siteConfig.id === 'mi' ? (
        <div className="contact">
          <a href={teamMember.linkedIn} target="_blank">
            <img
              className="linked-in"
              src={withPrefix('/images/company/linked-in.svg')}
              alt="Connect on LinkedIn"
            />
          </a>
        </div>
      ) : null}
    </>
  );
}

function TeamMemberDetail({ teamMember, circular, fade, siteConfig }) {
  return (
    <div
      className={'active-member team-member' + (circular ? ' circular' : '')}>
      <div
        className="image"
        style={{
          backgroundColor: teamMember.color
        }}>
        <img
          className={fade ? 'fade' : ''}
          src={teamMember.image}
          alt={teamMember.name}
        />
      </div>
      <div className="hand name">{teamMember.name}</div>
      <TeamContact teamMember={teamMember} siteConfig={siteConfig} />
    </div>
  );
}

function TeamList({ teamKey, mobile, selectedMember, siteConfig }) {
  const uf = useQueryParam('uf', null);
  const fadeTimeout = useRef();
  const [fade, setFade] = useState(null);
  const [activeMember, setActiveMember] = useState(
    selectedMember ? selectedMember : null
  );

  useEffect(() => {
    if (uf) {
      setActiveMember(uf);
    }
  }, [uf]);

  useEffect(() => {
    if (activeMember) {
      if (fadeTimeout.current) {
        clearTimeout(fadeTimeout.current);
      }

      setFade(true);

      fadeTimeout.current = setTimeout(() => {
        setFade(false);
      }, 500);
    }
  }, [activeMember]);

  return !mobile ? (
    <>
      <ul
        className={
          'team-list' +
          (Object.keys(teams[teamKey].members).length > 4 &&
          activeMember &&
          siteConfig.id === 'mi'
            ? ' circular'
            : '')
        }>
        {Object.keys(teams[teamKey].members)
          .filter((memberKey) => memberKey !== activeMember)
          .map((memberKey, memberIndex) => (
            <li
              className={'team-member' + ' member-' + memberIndex}
              key={memberKey}>
              <Button
                onClick={() => {
                  if (siteConfig.id === 'mi') {
                    setActiveMember(memberKey);
                  }
                }}>
                <div
                  className="image"
                  style={{
                    backgroundColor: teams[teamKey].members[memberKey].color
                  }}>
                  <img
                    src={teams[teamKey].members[memberKey].image}
                    alt={teams[teamKey].members[memberKey].name}
                  />
                </div>
                <div className="hand name">
                  {teams[teamKey].members[memberKey].name}
                </div>
              </Button>
            </li>
          ))}
      </ul>

      {activeMember &&
      teams[teamKey].members[activeMember] &&
      siteConfig.id === 'mi' ? (
        <TeamMemberDetail
          teamMember={teams[teamKey].members[activeMember]}
          circular={Object.keys(teams[teamKey].members).length > 4}
          fade={fade}
          siteConfig={siteConfig}
        />
      ) : null}
    </>
  ) : (
    <div className="mobile-team-list">
      {Object.keys(teams[teamKey].members)
        .filter(
          (memberKey) => siteConfig.id === 'mi' || memberKey !== activeMember
        )
        .map((memberKey, memberIndex) => (
          <TeamMemberDetail
            teamMember={teams[teamKey].members[memberKey]}
            siteConfig={siteConfig}
          />
        ))}
    </div>
  );
}

function Team({ siteConfig, team, selectedMember, singleMode, includeTitle }) {
  const [activeTeam, setActiveTeam] = useState(team ? team : 'sales');
  const [contactRequest, setContactRequest] = useState({});

  return siteConfig ? (
    <>
      {(!team || includeTitle) && !singleMode ? (
        siteConfig.id === 'mi' ? (
          <div id="anchorTeam" className="section segway team">
            <strong>Meet the {siteConfig.brand} Team</strong>An Incredible Team{' '}
            <span className="math">+</span> Innovative Ideas{' '}
            <span className="math">=</span> One Amazing Product
          </div>
        ) : (
          <div id="anchorTeam" className="section segway team">
            A Great Product Starts with a Great Team
            <strong>Meet the {siteConfig.brand} Team</strong>
          </div>
        )
      ) : null}

      <div className={'section team' + (singleMode ? ' single-mode' : '')}>
        <div className="section-content">
          {!team && !singleMode && siteConfig.id === 'mi' ? (
            <p>
              {siteConfig.brand} was developed in-house by an innovative team
              with over 25 years of experience in the multi-family industry
              ranging from property management to utility billing. Our own
              personal moving mishaps and valuable resident feedback have given
              us special insight into developing a software product we are proud
              of.
            </p>
          ) : null}

          {!team && !singleMode && siteConfig.id === 'mi' ? (
            <div className="buttons">
              <Button
                className={'sales' + (activeTeam === 'sales' ? ' active' : '')}
                onClick={() => setActiveTeam('sales')}>
                <span className="icon">
                  <InsertEmoticonIcon />{' '}
                </span>{' '}
                {teams.sales.teamName}
              </Button>

              <Button
                className={'dev' + (activeTeam === 'dev' ? ' active' : '')}
                onClick={() => setActiveTeam('dev')}>
                <span className="icon">
                  <CodeIcon />
                </span>{' '}
                {teams.dev.teamName}
              </Button>
            </div>
          ) : null}

          {!singleMode && (
            <>
              <TeamList
                key={activeTeam}
                teamKey={activeTeam}
                selectedMember={selectedMember}
                siteConfig={siteConfig}
              />

              <TeamList
                key={activeTeam + '_mobile'}
                teamKey={activeTeam}
                selectedMember={selectedMember}
                siteConfig={siteConfig}
                mobile
              />
            </>
          )}

          {!team && (singleMode || siteConfig.id === 'mi') ? (
            <div className="contact-section">
              <h2>Want to know more?</h2>
              {singleMode ? (
                <p>
                  {selectedMember ? (
                    <>
                      You can reach me directly at{' '}
                      <a
                        href={
                          'mailto:' +
                          getTeamMemberEmail(
                            teams.sales.members[selectedMember],
                            siteConfig
                          )
                        }
                        className="link">
                        {getTeamMemberEmail(
                          teams.sales.members[selectedMember],
                          siteConfig
                        )}
                      </a>
                    </>
                  ) : (
                    'Please reach out to me directly, '
                  )}{' '}
                  or fill out the form below and we’ll get back to you.
                </p>
              ) : (
                <p>
                  Please reach out to one of us directly, or fill out the form
                  below and we’ll get back to you.
                </p>
              )}

              {singleMode && selectedMember && (
                <>
                  <TeamMemberDetail
                    siteConfig={siteConfig}
                    teamMember={teams.sales.members[selectedMember]}
                  />
                </>
              )}

              <ContactForm
                request={contactRequest}
                setRequest={setContactRequest}
                fullWidth
                fields={
                  <>
                    <TextField
                      variant="outlined"
                      label="Phone"
                      fullWidth
                      value={contactRequest['Phone']}
                      onChange={(event) =>
                        setContactRequest({
                          ...contactRequest,
                          ['Phone']: event.target.value
                        })
                      }
                      required
                    />

                    <TextField
                      variant="outlined"
                      label="Message"
                      fullWidth
                      multiline
                      value={contactRequest['Message']}
                      onChange={(event) =>
                        setContactRequest({
                          ...contactRequest,
                          ['Message']: event.target.value
                        })
                      }
                      required
                    />
                  </>
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  ) : null;
}

export default Team;
