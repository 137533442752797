import React, { useRef, useEffect, useCallback } from 'react';
import moment from 'moment';

export default function MarketingAgreement({ propertyName }) {
  return (
    <div>
      <p>
        <strong>SMARTERCHOICE REVENUE SHARING AGREEMENT</strong>
      </p>

      <p>
        This Revenue Sharing Agreement (this &ldquo;<strong>Agreement</strong>
        &rdquo;) is made and shall become effective as of{' '}
        {moment().format('MM/DD/YYYY')} (the &quot;
        <strong>Effective Date</strong>&quot;), by and between SmarterChoice
        LLC, an Arizona Limited Liability Company, (hereinafter referred to as
        &quot;<strong>SmarterChoice</strong>&rdquo;), and {propertyName}{' '}
        (hereinafter referred to as &quot;<strong>Client</strong>&rdquo;).
        SmarterChoice and Client are hereinafter collectively referred to as the
        &ldquo;<strong>Parties</strong>&rdquo;, and each a &ldquo;
        <strong>Party</strong>
      </p>

      <p>RECITALS</p>
      <p>
        WHEREAS, SmarterChoice is a web-based platform providing existing and
        prospective residents (each, a &ldquo;<strong>Resident</strong>&rdquo;,
        and collectively, the &ldquo;<strong>Residents</strong>&rdquo;) with the
        various products and services available through the{' '}
        <a href="http://www.movingin.com">www.SmarterChoice.com</a> website
        and/or SmarterChoice mobile app (the &ldquo;<strong>Services</strong>
        &rdquo;).
      </p>
      <p>
        WHEREAS, Client is the owner and/or manager of one or more multi-family
        properties (each a &ldquo;<strong>Property</strong>&rdquo;, and
        collectively, the &ldquo;<strong>Properties</strong>&rdquo;) in which
        Residents are in need of such Services.
      </p>
      <p>
        WHEREAS, SmarterChoice and Client desire to establish, pursuant to the
        terms of this Agreement, an arrangement whereby SmarterChoice will share
        a portion of the commission revenue received from proprietors of certain
        Services (each, a &ldquo;<strong>Provider</strong>&rdquo;, and
        collectively, the &ldquo;<strong>Providers</strong>&rdquo;) with Client.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>&nbsp;</strong>
          <strong>COMPENSATION</strong>
        </li>
      </ol>
      <p>
        <u>Revenue Sharing Compensation</u>. In consideration for Client
        providing updated Resident data to SmarterChoice and making the
        SmarterChoice platforms available to Residents at the Properties, and
        assuming Client&rsquo;s continued eligibility as set forth in this
        Agreement, SmarterChoice hereby agrees to provide compensation to Client
        as follows (the &ldquo;<strong>Revenue Sharing Compensation</strong>
        &rdquo;): A flat fee of $40.00 per paid commissionable event,
        &lsquo;commissionable event&rsquo; being defined as the purchase of an
        electricity plan by an Eligible Resident (as defined below) that results
        in the Provider of that plan paying SmarterChoice a commission for
        brokering the sale (the &ldquo;<strong>Commission</strong>&rdquo;).
        Client hereby recognizes that Commission receipts from Providers
        constitute the sole source of funds for SmarterChoice&rsquo;s payment of
        Revenue Sharing Compensation, and accordingly Client understands,
        acknowledges and agrees that SmarterChoice&rsquo;s receipt of
        corresponding Commission receipts from Providers constitutes a condition
        precedent to SmarterChoice&rsquo;s obligation to pay any related Revenue
        Sharing Compensation to Client, and unless and until SmarterChoice
        realizes and receives such Commission receipts, SmarterChoice shall have
        no obligation to pay to Client any Revenue Sharing Compensation under
        this Agreement.
      </p>
      <p>
        <u>Claw Back and Other Adjustments</u>. In the event Provider reduces
        future revenue receipts to offset, claw back or otherwise recoup
        previously paid Commission receipts, SmarterChoice retains the right to
        reduce future Client Revenue Sharing Compensation under this Agreement
        to recoup any portion of those Provider offsets, claw backs or
        recoupments that SmarterChoice had previously disbursed to Client.
        Additionally, SmarterChoice retains the right to adjust future Client
        Revenue Sharing Compensation to offset any and all other funds that may
        have been erroneously disbursed by SmarterChoice to Client.
      </p>
      <p>
        <u>Software Provider Charges</u>. If Clients property management
        software provider charges SmarterChoice a per unit, per property, per
        sale or similar &lsquo;add-on&rsquo; fee, beyond the basic software
        license fee, to access Clients Property or Resident data, that fee will
        be passed on to Client and deducted from Clients Revenue Sharing
        Compensation.
      </p>
      <p>
        <u>Timing and Manner of Payment</u>
        <strong>.</strong> Provided all eligibility requirements are met, the
        Revenue Sharing Compensation shall be provided on a quarterly basis as
        follows: A paper check, or ACH transfer, in the amount of the Revenue
        Sharing Compensation for the previous calendar quarter (the &ldquo;
        <strong>Revenue Period</strong>&rdquo;), together with reasonable
        web-based supporting documentation, shall be issued to Client on or
        about each April 15, July 15, October 15 and January 15 that this
        Agreement remains in effect.
      </p>
      <p>&nbsp;</p>
      <ol start="2">
        <li>
          <strong>&nbsp;</strong>
          <strong>ELIGIBILITY REQUIREMENTS.&nbsp;</strong>
        </li>
      </ol>
      <p>
        <u>Eligible Property</u>. Before SmarterChoice can begin providing
        Services, and before Client is eligible to participate in the Revenue
        Sharing Compensation component of this Agreement, Client must begin
        providing resident data to SmarterChoice in a manner acceptable to
        SmarterChoice.
      </p>
      <p>
        <u>Eligible Residents</u>. Client, as a Property owner or manager, shall
        be entitled to Revenue Sharing Compensation solely with respect to each
        Resident who enters into a rental agreement with Client (the &ldquo;
        <strong>Eligible Resident</strong>&rdquo;) and enrolls for Services
        which result in Commission receipts being received by SmarterChoice,
        during such time as Client is owner or manager of such Property. In no
        event and under no circumstances shall Revenue Sharing Compensation be
        owed or paid by SmarterChoice to Client with respect to any Resident who
        has enrolled for or used or received Services prior to or after
        Client&rsquo;s ownership or management of the corresponding Property,
        regardless of when such Resident became a Resident at the Property.
      </p>
      <p>
        <u>Eligible Receipts</u>. Revenue Sharing Compensation shall be based
        solely on Commission receipts paid to SmarterChoice by Providers for
        Eligible Residents during such time as Client is and remains owner or
        manager of the Property at which Eligible Resident resides, minus
        offsets, claw backs and adjustments made by Provider or otherwise due
        SmarterChoice.
      </p>
      <p>
        <u>Client as Property Owner Versus Client as Property Manager</u>. Only
        one owner or manager of each Property is eligible to participate in this
        Agreement with respect to such Property and to receive the Revenue
        Sharing Compensation as defined above. Accordingly, for each Property to
        which this Agreement applies, it is understood, acknowledged and agreed
        that the following conditions precedent and subsequent shall apply to
        this Agreement and any compensation to Client hereunder: (A) Revenue
        Sharing Compensation shall be owed by SmarterChoice only once as to each
        Eligible Resident of each Property; (B) In the event this Agreement or
        the substantive equivalent has been entered into with SmarterChoice and
        executed by both the owner of such Property and the manager of such
        Property as of the last day of any Revenue Period, only the owner of
        such Property shall be entitled to receipt of any such Revenue Sharing
        Compensation for such Revenue Period, and the compensation rights of the
        Property owner shall govern and take precedence over the compensation
        rights of the Property manager, subject to the remaining provisions of
        this Agreement, and provided, further, that a Property owner shall not
        be entitled to any Revenue Sharing Compensation for a Revenue Period
        where such Property owner does not continue to be the owner of record of
        the Property on the last day of such Revenue Period; (C) in no event
        shall any Property manager receive any Revenue Sharing Compensation for
        a Property for any Revenue Period if such Property manager is not
        actively providing management services at such Property as of the last
        day of the corresponding Revenue Period; (D) each Property manager shall
        be responsible for providing notice to the corresponding Property owner
        of this Agreement, or any substantive equivalent hereof, with
        SmarterChoice as required in relation to any contractual, legal or
        ethical duties owed by such Property manager to the corresponding
        Property owner. In the event of any dispute between any Property owner
        and Property manager in relation to the Services or Revenue Sharing
        Compensation under this Agreement, such dispute shall remain exclusively
        between such Property owner and Property manager.
      </p>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          <strong>&nbsp;</strong>
          <strong>MISCELLANEOUS PROVISIONS.</strong>
        </li>
      </ol>
      <p>
        <u>Term of Agreement.&nbsp;</u>This Agreement is on a month-to-month
        term and can be terminated by either party at any time, for any or no
        reason, with or without notice.
      </p>
      <p>
        <u>Non-Exclusivity.&nbsp;</u>Client acknowledges and agrees that this
        Agreement is non-exclusive and that SmarterChoice may provide and
        perform similar services for or on behalf of other parties engaged in
        the same or other businesses and at other properties, including without
        limitation, ones that compete with Client or Client Properties, and
        residents thereat.
      </p>
      <p>
        <u>Publicity</u>. Either Party may use the other Party&rsquo;s name,
        logo or likeness in acknowledging, announcing or otherwise publicizing
        the existence of this Agreement via their respective websites, social
        media accounts, or through other advertising, promotional or marketing
        efforts.
      </p>
      <p>
        <u>Independent Contractor</u>. No relationship of employment is created
        by this Agreement, it being understood that SmarterChoice will at all
        times be acting and performing solely as an independent contractor.
        Neither Party to this Agreement is authorized to be the agent or legal
        representative of the other for any purpose whatsoever. No Party shall
        have the authority or power to make any commitments of any
        responsibility expressed or implied in the name of the other or to
        assume or create any obligation or shall be liable for any acts or
        omissions of the other. All employment, insurance and tax matters and
        filings shall be handled accordingly.
      </p>
      <p>
        <u>Exclusive Control</u>. Each Provider has exclusive control, at their
        sole discretion, as to what qualifies as a commissionable event, the
        amount of commission owed for each such event, and when and how
        SmarterChoice receives payment of such commission. Providers may at any
        time, and at their sole discretion, modify the programs, policies, and
        payouts to which SmarterChoice relies on for generating Commission
        receipts. Client understands, acknowledges, and agrees that changes to
        such programs, policies and payouts are beyond SmarterChoice&rsquo;s
        ability to control and that SmarterChoice is not responsible for any
        impact such changes may have on Client.
      </p>
      <p>
        <u>Governing Law</u>. All claims and disputes related to this Agreement,
        its Exhibits, the transactions contemplated (t)hereunder and the subject
        matter (t)hereof, including without limitation the Compensation and
        Eligibility Requirements, shall be settled in accordance with the
        Dispute Resolution provisions set forth in this Agreement.
        Interpretation and performance of this Agreement shall be in accordance
        with and pursuant to the substantive laws of the State of Arizona,
        without regard to principles of conflicts of laws.
      </p>
      <p>
        <u>Dispute Resolution</u>. Any dispute, controversy or claim that may
        arise under or in relation to Agreement, its Exhibits, the transactions
        contemplated (t)hereunder and the subject matter (t)hereof, including
        without limitation the Compensation and Eligibility Requirements, or the
        enforcement or interpretation of this Agreement or its Exhibits (each, a
        &ldquo;<strong>Dispute</strong>&rdquo;, and collectively, &ldquo;
        <strong>Disputes</strong>&rdquo;), shall be submitted to a mediator
        located in the Phoenix, Arizona metropolitan area, prior to any
        arbitration or other action. The mediator&rsquo;s fees and expenses
        shall be shared equally by the Parties who agree to exercise their best
        efforts in good faith to resolve all disputes in mediation. Mediation
        begins on the date one Party sends written notice to the other
        requesting mediation and presenting in the notice the matter to be
        mediated. The mediation shall conclude when all parties thereto sign an
        agreement that resolves the subject of the mediation. If no agreement is
        reached within sixty (60) calendar days after the date of the original
        written notice, the mediation is considered unsuccessful. In the event
        any Dispute cannot be settled by the Parties informally or through
        mediation, the Dispute shall be submitted to binding arbitration before
        a mutually agreeable arbitrator in the Phoenix, Arizona metropolitan
        area. In the event the Parties cannot agree on an arbitrator, the
        presiding judge of the Superior Court of Maricopa County, Arizona, shall
        decide who the arbitrator shall be. The arbitrator shall have the
        discretion to enter any award permissible under Applicable Law, and such
        award shall be enforceable exclusively in a state or federal court of
        competent jurisdiction in Maricopa County, Arizona. Client hereby
        consents to the personal jurisdiction of such courts located in Maricopa
        County, Arizona, for such purpose. CLIENT ALSO HEREBY WAIVES RIGHT TO A
        JURY TRIAL. Should any arbitration or other legal proceeding concerning
        any provision of this Agreement or the rights and duties of any person
        or entity in relation hereto be commenced among the Parties, or should
        any Party institute any proceeding in a bankruptcy or similar court
        which has jurisdiction over any Party or any or all of such other
        Party&apos;s property, the Party or Parties prevailing in such
        arbitration or proceeding shall be entitled, in addition to such other
        relief as may be granted, to a reasonable sum for their attorneys&rsquo;
        fees and court costs in such arbitration or proceeding which fees and
        costs shall be determined by the arbitrator or court, as the case may
        be, in such proceeding or in a separate action brought for that purpose.
      </p>
      <p>
        <u>Limitation of Liability</u>. Except where prohibited by law, in no
        event will SmarterChoice be liable to Client for any indirect,
        consequential, exemplary, incidental, or punitive damages, including
        lost profits, even if SmarterChoice has been advised of the possibility
        of such damages, except where SmarterChoice&rsquo;s acts and omissions
        have constituted reckless conduct, willful misconduct or intentional
        wrongdoing.
      </p>
      <p>
        <u>Indemnity</u>. Client shall indemnify and hold SmarterChoice, its
        officers, directors, shareholders, predecessors, successors in interest,
        employees, agents, subsidiaries and affiliates, harmless from any
        demands, loss, liability, claims or expenses (including attorneys&rsquo;
        fees), made against SmarterChoice by any third party due to or arising
        out of or in connection with Client&rsquo;s participation in this
        Agreement.
      </p>
      <p>
        <u>Assignment</u>. Either Party may freely assign its rights,
        obligations or claims under or with respect to this Agreement, at any
        time, in whole or part, with or without the other Party&rsquo;s consent
      </p>
      <p>
        <u>Invalid Provisions / Severability</u>. If any provision of this
        Agreement is held to be illegal, invalid or unenforceable under present
        or future laws, such provision shall be fully severable; this Agreement
        shall be construed and enforced as if such illegal, invalid or
        unenforceable provision had never comprised a part (t)hereof; and the
        remaining provisions of this Agreement shall remain in full force and
        effect and shall not be affected by the illegal, invalid or
        unenforceable provision or its severance from this Agreement.
        Furthermore, in lieu of such illegal, invalid or unenforceable
        provision, there shall be added automatically as part hereof a provision
        as similar in terms, but in any event no more restrictive than, such
        illegal, invalid or unenforceable provision as may be possible and be
        legal, valid and enforceable.
      </p>
      <p>
        <u>Modification</u>. Except as otherwise expressly provided herein, this
        Agreement and its Exhibits may not be changed, modified, or amended
        without the written consent of an actually authorized representative of
        both SmarterChoice and Client.
      </p>
      <p>
        <u>Interpretation</u>; Construction. The headings set forth in this
        Agreement are for convenience only and shall not be used in interpreting
        this Agreement. They are not to be construed as a part of this
        Agreement, and they in no way define, limit or amplify the provisions of
        this Agreement. This Agreement has been drafted by legal counsel
        representing SmarterChoice, but Client has had an opportunity to review
        this Agreement and have it reviewed by legal counsel, if desired, and,
        therefore, the normal rule of construction to the effect that any
        ambiguities are to be resolved against the drafting party shall not be
        employed in the interpretation of this Agreement.
      </p>
      <p>
        <u>Force Majeure</u>. SmarterChoice shall not under any circumstances be
        liable for any damages or penalty for any delay in performance of, or
        failure to perform, any obligation hereunder or for failure to give
        Client prior notice thereof, when such delay or failure is due to the
        elements, acts of God, government regulation, political instability,
        acts of war, acts of terrorism, power outages, changes in law, strikes,
        disease, viruses, bacteria, epidemics, pandemics or health threats as
        declared by a government or health care agency, insurrections, attacks
        or politically destabilizing events or circumstances as declared by a
        government agency or authority, or other causes beyond
        SmarterChoice&rsquo;s control. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
      </p>
      <p>
        <u>Notices</u>. Any notice to be given under this Agreement shall be in
        writing and shall be deemed to have been given when submitted by
        verifiable electronic mail, sent to the email address as designated
        under each Parties signature below. The addresses and/or contact points
        may be changed from time to time by either Party by serving notice to
        the other Party.
      </p>
      <p>
        <u>Survival</u>. The respective rights and obligations of the Parties
        under this Agreement, shall survive the expiration or termination of
        this Agreement.
      </p>
      <p>
        <u>Entire Agreement</u>. This Agreement represents the entire agreement
        between SmarterChoice and Client and supersedes any prior agreements
        executed by SmarterChoice and Client related to the subject matter of
        this Agreement. With respect thereto, there are no promises, terms,
        conditions, or obligations between SmarterChoice and Client other than
        those contained herein.
      </p>
      <p>
        <u>Counterparts</u>. This Agreement, and any document incorporated
        herein and integrated herewith by reference may be executed in any
        number of counterparts which, when taken together, constitute one and
        the same instrument. Each Party acknowledges that an executed copy
        (including, without limitation, copies of any signatures) that is
        reproduced and transmitted to the other Party electronically, by
        facsimile, email attachment, or otherwise, is equivalent to an original
        document for all purposes.
      </p>
    </div>
  );
}
